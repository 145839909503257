import { computed, reactive, readonly, ref } from 'vue'
import { ManageModule } from '/~/types/api/modules'
import emitter from '/~/core/emitter'
import { useProvider } from '/~/composables/provider'
import { DICT, DICTKey, DICTModule } from './dict'

type Meta = DICTModule & { task?: unknown }

interface Task {
  name: DICTKey
  meta: Meta
  task: unknown
}

type Tasks = {
  [key: string]: Task
}

interface Extensions {
  loaded: boolean
  instances: unknown[]
  tasks: Tasks
}

type Ext = Meta | null

const extensions = reactive<Extensions>({
  loaded: false,
  instances: [],
  tasks: {},
})

const modules = ref<ManageModule[]>([])

function setModules(modulesData: ManageModule[]) {
  modules.value = modulesData
  const bankFileModule = modulesData.find(
    (extension) => extension.moduleName === 'bank-file-upload'
  )

  modules.value = modules.value.filter(
    (extension) =>
      !['bank-file-upload', 'payroll'].includes(extension.moduleName)
  )
  if (bankFileModule) {
    if (bankFileModule.config.business?.enabled) {
      modules.value.push({
        id: 'bank-file-upload',
        moduleName: 'bank-file-upload',
        label: 'Bank File Upload',
        config: {},
      })
    }
    if (bankFileModule.config.payroll?.enabled) {
      modules.value.push({
        id: 'payroll',
        moduleName: 'payroll',
        label: 'Payroll',
        config: {},
      })
    }
  }
}

const tasksByPaths = computed(() => {
  const items: Record<string, unknown> = {}

  for (const task of Object.values(extensions.tasks)) {
    if (task.meta && Array.isArray(task.meta.roots)) {
      task.meta.roots.forEach((path) => {
        items[path] = task.task
      })
    } else {
      items[`/${task.name}`] = task.task
    }
  }

  return items
})

function getManifestByName(name: string) {
  return modules.value.find((ext) => ext.moduleName === name)
}

function getManifestByLabel(label: string) {
  return modules.value.find((ext) => ext.label === label)
}

function getManifestByType(type: string) {
  return modules.value.find((ext) => ext.type === type)
}

function getConfigByName(name: string) {
  const extension = getManifestByName(name)

  return extension?.config ?? null
}

function getMetaByName(name: string) {
  const extension = getManifestByName(name)

  return extension?.meta ?? null
}

function setLoaded() {
  extensions.loaded = true
  emitter.emit('extensions-loaded')
}

function getExtensionNameByPath(path: string) {
  const pathParts = path.split('/').filter(Boolean)

  for (let i = pathParts.length - 1; i >= 0; i--) {
    const rootPath = '/' + pathParts.slice(0, i + 1).join('/')

    for (const extName in DICT) {
      if (DICT[extName].roots?.some((root) => root === rootPath)) {
        return extName
      }
    }
  }

  return null
}

async function createTaskByPath(path: string) {
  const extension =
    getExtensionNameByPath(path) ??
    path
      .split('/')
      .filter((i) => i)
      .shift()

  return createTask(extension)
}

function isExtensionReady(name: string) {
  return !DICT[name] || Boolean(extensions.tasks[name])
}

function initExtensions() {
  if (extensions.loaded) {
    return
  }

  return createTasks()
}

function createTasks() {
  const tasks = modules.value.map((extension) => {
    return createTask(extension)
  })

  return Promise.all(tasks).then(() => {
    setLoaded()
  })
}

async function initExtension(extensionName?: string) {
  if (!extensionName) return

  const extension = getManifestByName(extensionName)

  if (!extension) {
    throw new Error(`Extension ${extension} not found`)
  }

  return createTask(extension)
}

async function createTask(extension?: string | ManageModule) {
  if (!extension) {
    return Promise.resolve()
  }

  const { type, moduleName, config } =
    typeof extension === 'string'
      ? { type: extension, moduleName: extension }
      : extension

  if (config && 'enabled' in config && config.enabled === false) {
    return Promise.resolve()
  }

  const ext: Ext =
    type === 'community-hub'
      ? DICT['community-hub']
      : DICT[moduleName as DICTKey] ?? null
  const moduleExist = await getTaskByPath(moduleName)

  if (!ext || moduleExist) {
    return Promise.resolve()
  }

  if (ext.src instanceof Function) {
    ext.task = ext.src().then((module) => {
      if (module.default && module.default.constructor instanceof Function) {
        const ExtensionWrapper = module.default
        const Extension = new ExtensionWrapper({
          type,
          moduleName,
        })

        extensions.instances.push(Extension)
        return Extension.install()
      }
    })
  }

  const task: Task = {
    name: moduleName as DICTKey,
    meta: ext,
    task: ext.task,
  }

  extensions.tasks = {
    ...extensions.tasks,
    [task.name]: task,
  }

  return ext.task
}

function getExtensionByPath(path = '') {
  const pathParts = path.split('/').filter(Boolean)

  for (let name of pathParts) {
    if (name === 'cinema') {
      name = 'gift-cards-cinema'
    }

    const extension = getManifestByName(name)

    if (extension) {
      return { extension, name, pathParts }
    }
  }

  return { extension: null, name: pathParts[0], pathParts }
}

function getTaskByPath(path: string): Promise<boolean> {
  return new Promise((resolve) => {
    const { extension, name, pathParts } = getExtensionByPath(path)
    let task = tasksByPaths.value[`/${name}`]

    /**
     * TODO: Improve extension type by extend with other module types
     * @file src/types/api/modules/index.d.ts
     */
    if (extension?.type === 'community-hub') {
      task = tasksByPaths.value[`/${extension.type}`]
    } else {
      for (let i = pathParts.length - 1; i > 0; i--) {
        const rootPath = '/' + pathParts.slice(0, i + 1).join('/')

        if (tasksByPaths.value[rootPath]) {
          task = tasksByPaths.value[rootPath]
          break
        }
      }
    }

    // Do not load the extension if its path does not include the parent route
    const { currentTemplateConfig } = useProvider()
    const templateConfig = currentTemplateConfig.value?.extensions?.[name] ?? {}
    const parentRoute = templateConfig.parentRoute ?? null

    if (parentRoute && !path.includes(parentRoute)) {
      resolve(false)
    }

    // Try to load extension
    if (task instanceof Promise) {
      task
        .then(() => {
          resolve(true)
        })
        .catch((err) => {
          console.error(err)
          resolve(false)
        })
    } else if (task) {
      resolve(true)
    } else {
      resolve(false)
    }
  })
}

export const useExtensions = () => ({
  getManifestByName,
  getManifestByLabel,
  getManifestByType,
  getConfigByName,
  getMetaByName,
  getTaskByPath,

  extensions,

  createTask,
  createTaskByPath,
  initExtensions,
  initExtension,

  isExtensionReady,

  setModules,
  modules: readonly(modules),
})
