import { reactive } from 'vue'
import { PayByAccountManager } from './core/PayByAccountManager'

const payByAccountManager = reactive(
  new PayByAccountManager({
    createUrl: '/v3/payment-methods/pay-by-accounts',
  })
)

export function usePayByAccount() {
  return {
    payByAccountManager,
  }
}
