import api from '/~/core/api'
import { PayByAccount, PayByAccountItem } from './PayByAccount'

type Params = {
  createUrl: string
}

export class PayByAccountManager {
  params: Params
  loading = false
  payByAccount?: PayByAccount

  constructor(params: Params) {
    this.params = params
  }

  async create() {
    try {
      this.loading = true
      const { data } = (await api.post(this.params.createUrl, {})) as {
        data: PayByAccountItem[]
      }

      this.payByAccount = new PayByAccount(data)
    } catch (error) {
      console.error('pay-by-account-creation', error)
      throw error
    } finally {
      this.loading = false
    }
  }
}
