export const businessNumber = {
  mask: '00-0000000',
  placeholder: '00-0000000',
  length: 10,
  unmaskedLength: 9,
}

export const postcode = {
  mask: '#####-####',
  definitions: {
    '#': /\d/,
  },
  lazy: true,
}

export const bsb = {
  length: 9,
}
