import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'

enum PayByAccountPaymentType {
  payTo = 'PayByAccountPayTo',
  becs = 'PayByAccountBecs',
}

export class PayByAccountItem {
  id: string
  paymentType: PayByAccountPaymentType

  constructor(id: string, paymentType: PayByAccountPaymentType) {
    this.id = id
    this.paymentType = paymentType
  }
}

const FAKE_PAY_BY_ACCOUNT_ID = 'pba_fake_id'

export class PayByAccount {
  id: string = FAKE_PAY_BY_ACCOUNT_ID
  items: PayByAccountItem[] = [
    {
      id: '',
      paymentType: PayByAccountPaymentType.payTo,
    },
    {
      id: '',
      paymentType: PayByAccountPaymentType.becs,
    },
  ]

  type: PaymentMethodType = PaymentMethodType.payByAccount

  constructor(items: PayByAccountItem[]) {
    if (items.length) {
      this.id = items[0].id
      this.items = items
    }
  }

  get isFake() {
    return this.id === FAKE_PAY_BY_ACCOUNT_ID
  }

  getByPaymentType(paymentType: PayByAccountPaymentType) {
    return this.items.find((item) => item.paymentType === paymentType)
  }
}
