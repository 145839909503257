import {
  ActivityItemMetadataStatus,
  ActivityItemType,
} from '/~/composables/activity/activity-enums'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { useLocalization } from '/~/composables/localization'

const { translate } = useLocalization()

const REFUND_ICON_CLASS =
  'flex items-center justify-center bg-red-700 text-white p-2.5 m-1 rounded-full max-w-11 max-h-11'

export class ActivityStatus {
  // TODO: make other properties private so no overlap with parent properties
  status: ActivityItemMetadataStatus
  type: ActivityItemType
  scheduledAt?: string
  _financialStatus?: string
  _flowType?: FlowType
  constructor(
    status: ActivityItemMetadataStatus,
    params: {
      type?: ActivityItemType
      scheduledAt?: string
      financialStatus?: string
      flowType?: FlowType
    } = {}
  ) {
    this.status = status?.toLowerCase() as ActivityItemMetadataStatus
    this.type = params.type || ActivityItemType.PurchaseOrder
    this.scheduledAt = params.scheduledAt
    this._financialStatus = params.financialStatus
    this._flowType = params.flowType
  }

  get isStatusPurchaseOrderCompleted() {
    if (
      this._financialStatus === 'paid' &&
      this._flowType === FlowType.purchase
    ) {
      return [
        ActivityItemMetadataStatus.Completed,
        ActivityItemMetadataStatus.Processing,
      ].includes(this.status)
    }
  }

  get isStatusCompleted() {
    const statuses = [
      ActivityItemMetadataStatus.Completed,
      ActivityItemMetadataStatus.Approved,
      ActivityItemMetadataStatus.Funded,
    ]

    return statuses.includes(this.status)
  }

  get isStatusPending() {
    const statuses = [
      ActivityItemMetadataStatus.Pending,
      ActivityItemMetadataStatus.Processing,
    ]

    return statuses.includes(this.status)
  }

  get isStatusAwaitingFunds() {
    const statuses = [ActivityItemMetadataStatus.AwaitingFundsPi]

    return statuses.includes(this.status)
  }

  get isStatusRemittancePending() {
    const statuses = [ActivityItemMetadataStatus.RemittancePending]

    return statuses.includes(this.status)
  }

  get isStatusGroupPending() {
    return this.isStatusPending || this.isStatusRemittancePending
  }

  get isStatusFailed() {
    const statuses = [
      ActivityItemMetadataStatus.Failed,
      ActivityItemMetadataStatus.Declined,
    ]

    return statuses.includes(this.status)
  }

  get isStatusRefunded() {
    const statuses = [ActivityItemMetadataStatus.Refunded]

    return statuses.includes(this.status)
  }

  get isStatusCompletedWithRemittanceFailures() {
    const statuses = [
      ActivityItemMetadataStatus.CompletedWithRemittanceFailures,
    ]

    return statuses.includes(this.status)
  }

  get isStatusIncomplete() {
    const statuses = [
      ActivityItemMetadataStatus.DestinationFailed,
      ActivityItemMetadataStatus.Incomplete,
    ]

    return statuses.includes(this.status)
  }

  // TODO: check if separate statuses should be used for cancelled and expired
  get isStatusCancelled() {
    const statuses = [
      ActivityItemMetadataStatus.Cancelled,
      ActivityItemMetadataStatus.Expired,
    ]

    return statuses.includes(this.status)
  }

  get isTypeCampaign() {
    return this.type === ActivityItemType.Campaign
  }

  // Order statuses
  get isStatusScheduled() {
    return this.status === ActivityItemMetadataStatus.Scheduled
  }

  get isStatusPayeesSynced() {
    return this.status === ActivityItemMetadataStatus.PayeesSynced
  }

  get isTypeProgramOrder() {
    return this.type === ActivityItemType.PointsProgramOrder
  }

  get statusLabel(): {
    text: string
    color: string
    icon: string
    iconClass?: string
    heading: string
  } {
    // Purchase order statuses
    if (this.isStatusPurchaseOrderCompleted) {
      return {
        text: this.isStatusCompleted ? 'Completed' : 'Processing',
        color: this.isStatusCompleted ? 'text-fg-success' : 'text-fg-warning',
        icon: 'heroicons/solid/check-circle',
        iconClass: 'text-fg-success',
        heading: 'Payment successful',
      }
    }
    // Refund statuses
    if (this.isStatusRefunded) {
      return {
        text: 'Refunded',
        color: 'text-fg-success',
        icon: 'heroicons/micro/arrow-uturn-left',
        iconClass: REFUND_ICON_CLASS,
        heading: 'Payment returned',
      }
    }

    if (this.type === ActivityItemType.Refund) {
      if (this.isStatusPending) {
        return {
          text: 'Pending',
          color: 'text-fg-warning',
          icon: 'heroicons/micro/arrow-uturn-left',
          iconClass: REFUND_ICON_CLASS,
          heading: 'Payment returned',
        }
      }

      if (this.isStatusFailed) {
        return {
          text: 'Failed',
          color: 'text-fg-error',
          icon: 'heroicons/micro/arrow-uturn-left',
          iconClass: REFUND_ICON_CLASS,
          heading: 'Payment returned',
        }
      }
    }

    // Activity statuses

    if (this.isStatusCompleted || this.isTypeCampaign) {
      return {
        text: 'Completed',
        color: 'text-fg-success',
        icon: 'heroicons/solid/check-circle',
        heading: this.isTypeProgramOrder
          ? 'Points Transfer'
          : 'Payment successful',
      }
    }

    if (this.isStatusPending) {
      return {
        text: 'Processing',
        color: 'text-fg-warning',
        icon: 'heroicons/solid/clock',
        heading: 'Payment processing',
      }
    }

    if (this.isStatusRemittancePending) {
      return {
        text: 'Remittance pending',
        color: 'text-fg-warning',
        icon: 'heroicons/solid/clock',
        heading: 'Remittance pending',
      }
    }

    if (this.isStatusAwaitingFunds) {
      return {
        text: `${translate('common.awaiting')} funds`,
        color: 'text-fg-warning',
        icon: 'heroicons/solid/clock',
        heading: 'Payment pending',
      }
    }

    if (this.isStatusFailed) {
      return {
        text: 'Failed',
        color: 'text-fg-error',
        icon: 'heroicons/solid/x-circle',
        heading: 'Payment failed',
      }
    }

    if (this.isStatusCancelled) {
      return {
        text: 'Cancelled',
        color: 'text-fg-error',
        icon: 'heroicons/solid/x-circle',
        heading: 'Payment cancelled',
      }
    }

    if (this.isStatusIncomplete) {
      return {
        text: 'Incomplete',
        color: 'text-fg-error',
        icon: 'heroicons/solid/x-circle',
        heading: 'Payment failed',
      }
    }

    if (this.isStatusCompletedWithRemittanceFailures) {
      return {
        text: 'Payment partially completed',
        color: 'text-fg-warning',
        icon: 'heroicons/solid/exclamation-circle',
        heading: 'Payment partially completed',
      }
    }

    // Order statuses taken from Symbion prior 1.5 UI
    if (this.isStatusScheduled) {
      return {
        text: 'Scheduled',
        color: 'text-fg-warning',
        icon: 'heroicons/mini/calendar-days',
        iconClass: 'text-white p-2.5 bg-fg-warning rounded-full',
        heading: 'Payment scheduled',
      }
    }

    if (this.isStatusPayeesSynced) {
      return {
        text: 'Completed',
        color: 'text-fg-success',
        icon: 'heroicons/solid/check-circle',
        heading: 'Payment successful',
      }
    }

    // Default case for undefined or unknown statuses
    return {
      text: 'Loading',
      color: 'text-fg-warning',
      icon: 'heroicons/solid/clock',
      heading: 'Payment is loading',
    }
  }
}
