export const businessNumber = {
  mask: '00 000 000 000',
  placeholder: '00 000 000 000',
  length: 14,
  unmaskedLength: 11,
}

export const postcode = {
  mask: '0000',
  lazy: true,
}

export const bsb = {
  length: 6,
}
